<template>
  <div>
    <dashboard-page-title :showAddBtn="false">{{ pageTitle }}</dashboard-page-title>

    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <b-row>
          <b-col md="12" class="mb-4">
            <cropper-images
              :label="$t('main.flag')"
              nameOfImage="image.jpg"
              @cropper-save="saveImage"
              :progressLoading="progressImage"
              :multi="false"
              :imageUrl="item.flag"
            />
          </b-col>
          <!-- <b-col md="6" class="mb-3">
            <input-form :placeholder="$t('main.name')" :label="$t('main.name')" v-model="item.name" :name="$t('main.name')" validate="required"></input-form>
          </b-col>
          <b-col md="6" class="mb-3">
            <input-form :placeholder="$t('main.name')" :label="$t('main.name')" v-model="item.name" :name="$t('main.name')" validate="required"></input-form>
          </b-col> -->
          <b-col md="12" class="mb-3">
            <translation-input :placeholder="$t('main.name')" :label="$t('main.name')" :arrayOfTranslation="item.translations" :name="$t('main.name')" validate="required" :disabled="disabled"/>
          </b-col>

          <b-col md="6" class="mb-3">
            <input-form :placeholder="$t('main.code')" :label="$t('main.code')" v-model="item.code" :name="$t('main.code')" validate="required" :disabled="disabled"></input-form>
          </b-col>

          <b-col cols="12" v-if="!disabled">
            <b-button variant="primary" type="submit" :disabled="loadingSubmit">
              <span v-if="!loadingSubmit">{{ $t('main.save') }}</span>
              <template v-else>
                <spinner-loading class="d-inline"></spinner-loading>
                <span>{{ $t('main.loading') }}</span>
              </template>
            </b-button>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import globalAdd from '@/mixins/globalAdd'
import countriesServices from '../services/countries'

export default {
  mixins: [globalAdd],
  mounted () {
    core.index()
  },
  props: { id: { type: [String, Number], default: '' } },
  data () {
    return {
      pageName: this.$route?.meta?.name || '',
      loadingSubmit: true,
      progressImage: 0,
      item: {
        translations: [],
        code: '',
        flag: ''
      }
    }
  },
  methods: {
    getDetails () {
      countriesServices.getDetails(this.id).then(response => {
        const translations = this.handleResponseTranselation(['name'], response?.data?.translations || [])
        this.item = {
          translations: translations,
          code: response?.data?.code || '',
          flag: response?.data?.flag || ''
        }
        this.loadingSubmit = false
      })
    },
    saveImage (file) {
      const formData = new FormData()
      formData.append('file', file.image)
      const options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent
          const percent = Math.floor((loaded * 100) / total)
          this.progressImage = percent
        }
      }
      this.commonUploadFiles(formData, options).then(res => {
        this.item.flag = res.data
        this.showSuccessUploadFile()
      })
    },
    onSubmit () {
      this.loadingSubmit = true
      if (this.id) {
        countriesServices.update(this.id, this.item).then(res => {
          core.showSnackbar('success', this.$t('main.updatedSuccessfully'))
          this.loadingSubmit = false
          this.$router.push({ name: 'countries' })
        }).catch(() => {
          this.loadingSubmit = false
        })
      } else {
        countriesServices.add(this.item).then(res => {
          core.showSnackbar('success', this.$t('main.addedSuccessfully'))
          this.loadingSubmit = false
          this.$router.push({ name: 'countries' })
        }).catch(() => {
          this.loadingSubmit = false
        })
      }
    }
  },
  computed: {
    pageTitle () {
      switch (this.pageName) {
        case 'countries.add':
          return this.$t('countries.add')
        case 'countries.update':
          return this.$t('countries.update')
        default:
          return this.$t('countries.details')
      }
    },
    disabled () {
      switch (this.pageName) {
        case 'countries.add':
        case 'countries.update':
          return false
        default:
          return true
      }
    }
  },
  created () {
    if (this.id) {
      this.getDetails()
    } else {
      this.item.translations = this.handleResponseTranselation(['name'], [])
      this.loadingSubmit = false
    }
  }
}
</script>
